.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0 0;
}

.ant-tabs-tab {
  justify-content: center;
}

.foo {
  padding-left: 5px;
  /* padding-right: 5px; */
  /* width: 24vw; */
}
