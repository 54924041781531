.header_layout {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  /* height: 10vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 5px;
  border: 1px solid #4f81bd;
}
.header_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 2vw;
}
