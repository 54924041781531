.styleButton {
  background-color: 'red';
  color: 'white';
}
.styleCard:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border: 1px solid #4f81bd !important;
}
.styleCardRed:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border: 1px solid #e84563 !important;
}
.styleCardGreen:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border: 1px solid #67cc59 !important;
}
