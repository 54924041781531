.ant-result-title {
  font-size: 18px;
}

.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin-top: 30vh;
}
.noResult {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin-top: 10vh;
}
